/**
 *  Kinty / Web / App
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Stylesheet for the app module
 *
 */

.header,
.sidebar {
  z-index: 100;
}
