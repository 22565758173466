/**
 *  Kinty / App / Features / Strategies / Pages / Empty
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Empty page in case the user doesn't have any watch lists
 *
 */

.root {
  margin: 32px 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.animation {
  width: 300px;
  height: 350px;
  margin: 0 auto;
  overflow: hidden;
}

.button {
  margin-top: 36px;
}
