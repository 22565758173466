/**
 *  Kinty / App / Pages / Sign Up
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Sign Up Page
 *
 */

.image {
  background-image: url("../../assets/sign-up.jpg");
}

.input + .input {
  margin-top: 32px;
}

.form {
  margin-top: 32px;
}

.text {
  text-align: center;
  margin: 32px 0;
  display: block;
}

.form .notification {
  width: 100%;
  margin-bottom: 32px;
}

.submit {
  margin-top: 32px;
}
