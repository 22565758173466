/**
 *  Kinty / App / Components / Credentials Layout
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Credentials layout is used in the Register / Sign In / Forgotten Password and Reset
 *  Password screens.
 *
 */

.root {
  display: flex;
  width: 100%;
  height: 100vh;
}

.form {
  flex-grow: 1;
  padding: 80px;
  display: flex;
}

.wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.header {
  text-align: right;
}

.banner {
  flex-basis: 512px;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
}

.contentWrapper {
  width: 380px;
}

.title {
  margin-bottom: 32px;
  text-align: center;
}

.logo {
  position: absolute;
  top: 64px;
  left: 80px;
  color: #f4f5f6;
  z-index: 5;
}

.themeToggle {
  position: absolute;
  bottom: 64px;
  right: 80px;
  z-index: 5;
}

.image {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 1;
  &:after {
    background: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: "";
  }
}

.content {
  flex-grow: 1;
  justify-content: center;
  display: flex;
  align-items: center;
}
