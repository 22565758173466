/**
 *  Primabuild Business Manager / Web / Components / Header
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Holds the header structure
 *
 */

.root {
  display: flex;
  flex-grow: 1;
}

.logo {
  flex-basis: 242px;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-grow: 1;
  align-items: center;
}
