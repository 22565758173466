/**
 *  Kinty / App / Pages / Sign In
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Sign In Page
 *
 */

.image {
  background-image: url("../../assets/sign-in.jpg");
}

.input + .input {
  margin-top: 32px;
}

.links {
  margin-top: 16px;
  text-align: right;
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: flex-end;
}

.form .notification {
  width: 100%;
  margin-bottom: 32px;
}

.submit {
  margin-top: 32px;
}
