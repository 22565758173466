/**
 *  Kinty / App / Features / Watchlists / Dialogs / Name
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Dialog that renders the input field for the name of the watchlist
 *
 */

.root {
  min-width: 448px;
}

.actions {
  display: flex;
  margin-top: 32px;
}

.button {
  width: 100%;
}
