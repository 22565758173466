/**
 *  Kinty / App / Dialogs / Destructive Dialog
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Dialog that asks for deleting / removing entity
 *
 */

.root {
  min-width: 448px;
}

.content {
  text-align: center;
}

.actions {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;
}

.button {
  flex-grow: 1;
}

.button {
  margin-right: 16px;
}

.button + .button {
  margin-left: 16px;
  margin-right: 0;
}
