/**
 *  Notifications / Web / Components / Notifications
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Holds the notifications logic
 *
 */

.root {
  position: fixed;
  top: 70px;
  left: 50%;
  z-index: 500;
}

.notification {
  position: absolute;
}

.notificationBody {
  background: white;
}
