/**
 *  Kinty / App / Features / Strategies / Pages / Create
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Create strategy page
 *
 */

.root {
}

.card {
  margin: 32px 0;
}

.title {
  margin-bottom: 32px;
}

.formTitle {
  margin-bottom: 8px;
}

.formSubtitle {
  margin-bottom: 6px;
  display: block;
}

.formContent {
  margin: 32px 0;
  display: flex;
}

.formField {
  padding-top: 24px;
  padding-bottom: 24px;
}

.fields {
  display: flex;
}

.fieldName {
  flex-grow: 1;
}

.fieldType {
  margin-left: 32px;
  flex-basis: 20%;
}

.fieldBalance {
  flex-grow: 1;
}

.fieldFunds {
  margin-left: 32px;
  flex-basis: 30%;
}

.fieldCondition {
  flex-basis: 20%;
}

.fieldDifference {
  flex-grow: 1;
  margin-left: 32px;
}

.fieldAction {
  flex-basis: 20%;
}
.fieldStopLoss {
  margin-left: 32px;
  flex-grow: 1;
}
.fieldProfitGain {
  margin-left: 32px;
  flex-grow: 1;
}

.summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  flex-basis: 40%;
  margin-left: 32px;
}

.button {
  width: 100%;
  white-space: nowrap;
}

.button + .button {
  margin-top: 16px;
}

.summaryCard .notification {
  width: 100%;
  margin-bottom: 32px;
}
