/**
 *  Kinty / App / Features / Strategies / Pages / Strategy / Trade / Details
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Strategy Trade Details
 *
 */

.root {
  display: flex;
  justify-content: space-between;
}
