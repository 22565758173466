/**
 *  Kinty / Web / Components / Header / Avatar
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The avatar for the header
 *
 */

.root {
  min-width: 224px;
}
