/**
 *  Notifications / Web / Components / Notifications
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Holds the notifications logic
 *
 */

.root {
  margin: 32px 0;
}

.table {
  padding: 0;
}

.actions {
  margin-bottom: 32px;
}

.separator {
  margin: 0;
}
