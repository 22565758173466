/**
 *  Kinty / App / Pages / Reset Password
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Reset Password Page
 *
 */

.image {
  background-image: url("../../assets/reset-password.jpg");
}

.input + .input {
  margin-top: 32px;
}

.form .notification {
  width: 100%;
  margin-bottom: 32px;
}

.submit {
  margin-top: 32px;
}
