/**
 *  Kinty / App / Features / Watchlists / Dialogs / Symbols
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Dialog that renders the input field for the name of the watchlist
 *
 */

.root {
  width: 800px;
}

.search {
}

.actions {
  display: flex;
  justify-content: flex-end;
}

.button {
  width: 100%;
}

.input {
  width: 100%;
}

.table {
  margin-top: 18px;
  height: 400px;
  overflow: scroll;
}

.ticker {
  width: 130px;
}
