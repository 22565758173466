/**
 *  Kinty / App / Pages / Forgotten Password
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Forgotten Password Page
 *
 */

.image {
  background-image: url("../../assets/forgotten-password.jpg");
}

.links {
  margin-top: 16px;
  display: flex;
  height: 20px;
  align-items: center;
  justify-content: center;
}

.form {
  margin-top: 32px;
}

.text {
  text-align: center;
  margin: 32px 0;
  display: block;
}

.form .notification {
  width: 100%;
  margin-bottom: 32px;
}

.submit {
  margin-top: 32px;
}
