/**
 *  Kinty / App / Features / Watchlists / Pages / Watchlists
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Watchlists Page
 *
 */

.root {
  margin: 32px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;
}

.list {
  flex-basis: 300px;
}

.separator {
  margin: 0;
}

.actions {
  display: flex;
}

.actionButton + .actionButton {
  margin-left: 16px;
}

.settingsMenu {
  min-width: 140px;
}

.settingsButton {
  margin-left: 16px;
}
