/**
 *  Kinty / App / Features / Strategies / Pages / Watchlists
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Strategies Page
 *
 */

.root {
  margin: 32px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;
}

.title {
  margin: 0;
}

.list {
  flex-basis: 300px;
}

.separator {
  margin: 0;
}

.actions {
  display: flex;
}

.actionButton + .actionButton {
  margin-left: 16px;
}

.settingsMenu {
  min-width: 140px;
}

.settingsButton {
  margin-left: 16px;
}

.viewButton {
  width: 120px;
  margin-left: auto;
}

.progress {
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.row {
  .viewButton {
    display: none;
  }
  &:hover {
    .viewButton {
      display: block;
    }
    .progress {
      display: none;
    }
  }
}
