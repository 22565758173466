/**
 *  Kinty / App / Components / Logo
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The logo for the header
 *
 */

@import "~@kinty-app/ui/dist/index.scss";

.root {
  transition: color 0.2s;

  @include theme-light {
    color: $neutral-2;
  }
  @include theme-dark {
    color: $neutral-7;
  }
}
