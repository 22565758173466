/**
 *  Kinty / App / Features / Strategies / Pages / Strategy / Trade
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Strategy Trade Page
 *
 */

.loss {
  color: #ff6838;
  font-weight: 700;
}

.profit {
  color: #58bd7d;
  font-weight: 700;
}

.root tr {
  td {
    border-bottom: none;
  }
  &:last-child td {
    border-bottom: 1px solid #e6e8ec;
  }
}

.root:last-child tr:last-child td {
  border-bottom: none;
}
