/**
 *  Primabuild Business Manager / Web / Components / Layout
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  Holds the main layout structure
 *
 */

@import "~@kinty-app/ui/dist/index.scss";

.root {
  min-height: calc(100vh - 80px);
  display: flex;
}

.empty {
  min-height: auto;
  display: block;
}

.contentWrapper {
  margin-top: 80px;
  flex-grow: 1;
}

.root .content {
  min-height: calc(100vh - 80px);
  align-items: flex-start;
}

.page {
  width: 100%;
}
