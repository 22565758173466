/**
 *  Kinty / App / Features / Strategies / Pages / Strategy
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 *
 *  The Strategy Page
 *
 */

.root {
  margin: 32px 0;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;
}

.title {
  margin: 0;
}

.separator {
  margin: 0;
}

.loss {
  color: #ff6838;
  font-weight: 700;
}

.profit {
  color: #58bd7d;
  font-weight: 700;
}
